export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'dashboard',
    action: 'read',
  },
  {
    title: 'Shipments',
    route: 'shipment',
    icon: 'FileIcon',
    resource: 'shipment',
    action: 'read',
  },
  {
    title: 'My Pickups',
    route: 'view_pickups',
    icon: 'PackageIcon',
    resource: 'pickup',
    action: 'read',
  },
  {
    title: 'Create new Pickup',
    route: 'create_pickup',
    icon: 'PlusIcon',
    resource: 'pickup',
    action: 'read',
  },
]
