<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notifications.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <a
          class="pr-1"
          href="#!"
          @click="read_all_notifications"
        >mark all as read</a>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ notifications.length === 0 ? 0 : notifications.length + ' New' }}
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <!--      click to mark notification as read -->
      <b-link
        v-for="(notification, index) in notifications"
        :key="notification.id"
        @click="read_notification(notification.data.url, notification.id, index)"
      >
        <b-media>
          <template>
            <!--            <b-avatar
                          size="32"
                          :src="require('@/assets/images/avatars/6-small.png')"
                          :text="require('@/assets/images/avatars/6-small.png')"
                          variant="light-success"
                        />-->
          </template>

          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.data.subject }}
            </span>
          </p>
          <small class="notification-text">{{ notification.data.message }}</small>

        </b-media>
      </b-link>

      <!-- System Notification Toggler -->
      <!--      <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">
          System Notifications
        </h6>
        <b-form-checkbox
          :checked="true"
          switch
        />
      </div>-->

      <!-- System Notifications -->
      <!--      <b-link
        v-for="notification in systemNotifications"
        :key="notification.subtitle"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :variant="notification.type"
            >
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>-->
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
      >Read all notifications
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BBadge, BButton, BLink, BMedia, BNavItemDropdown,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import axios from '@/libs/axios'
import { isUserLoggedIn } from '@/auth/utils'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    // BAvatar,
    VuePerfectScrollbar,
    BButton,
    // BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  setup() {
    /* eslint-disable global-require */

    /* const systemNotifications = [
      {
        title: 'Server down',
        subtitle: 'USA Server is down due to hight CPU usage',
        type: 'light-danger',
        icon: 'XIcon',
      },
      {
        title: 'Sales report generated',
        subtitle: 'Last month sales report generated',
        type: 'light-success',
        icon: 'CheckIcon',
      },
      {
        title: 'High memory usage',
        subtitle: 'BLR Server using high memory',
        type: 'light-warning',
        icon: 'AlertTriangleIcon',
      },
    ] */

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      // systemNotifications,
      perfectScrollbarSettings,
    }
  },
  data() {
    return {
      notifications: [],
      notification_numbers: 0,
    }
  },
  created() {
    this.checkNotifications()
  },
  mounted() {
    this.echoSystem()
  },
  methods: {
    async checkNotifications() {
      await axios.get('/unread_notifications').then(resp => {
        this.notifications = resp.data
        if (resp.data.length !== this.notification_numbers && this.notification_numbers !== 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: resp.data[0].data.message,
              icon: 'CheckIcon',
              variant: 'info',
            },
          })
        }
        this.notification_numbers = resp.data.length
      })
    },
    read_notification(url, id, index) {
      axios.post(`/read_notification/${id}`).then(resp => {
        if (resp.data) {
          this.notifications.splice(index, 1)
          this.notification_numbers = this.notifications.length
          this.$router.push(url)
        }
      })
    },
    read_all_notifications() {
      axios.post('/read_all_notification').then(resp => {
        if (resp.data) {
          this.notifications = []
          this.notification_numbers = this.notifications.length
        }
      })
    },
    play_notification_sound() {
      const sound = new Audio('/assets/sounds/notification.mp3')
      sound.play().then(() => {
        console.log('notification sound played')
      }).catch(err => {
        console.log(err)
        /* const confirmed = confirm('Need to get notification sound?')
        if (confirmed) {
          this.play_notification_sound()
        } */
      })
    },
    echoSystem() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      window.EchoInstance.channel(`App.Models.Client.${userData.id}`).notification(e => {
        const notification = {}
        notification.id = e.id
        notification.data = e
        console.log(notification)
        this.notifications.unshift(notification)
        this.play_notification_sound()
      })
    },
  },
}
</script>

<style>

</style>
